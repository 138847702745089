export default [
  {
    url: "/derma-specialist/shop",
    name: "Shop",
    icon: "HomeIcon",
    slug: "shop",
    i18n: "Shop"
  },
  {
    url: "/derma-specialist/orders",
    name: "Orders",
    slug: "orders",
    i18n: "Orders",
    icon: "ListIcon"
  },
  {
    url: "/derma-specialist/events",
    name: "Events",
    icon: "CalendarIcon",
    slug: "events",
    i18n: "Events"
  },
  {
    url: "/derma-specialist/resources",
    name: "Resources",
    icon: "FolderIcon",
    slug: "resources",
    i18n: "Resources"
  },
  {
    url: "/derma-specialist/settings",
    name: "Settings",
    slug: "settings",
    i18n: "Settings",
    icon: "SettingsIcon"
  }
];
